"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-45320557-809359/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _vue = _interopRequireDefault(require("vue"));
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
// svg component

// register globally
_vue.default.component('svg-icon', _SvgIcon.default);
var req = require.context('./svg', false, /\.svg$/);
var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};
requireAll(req);