"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isNumber = isNumber;
exports.isString = isString;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.validLowerCase = validLowerCase;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
exports.validateObjHaveValue = validateObjHaveValue;
exports.validatePhoneAndTel = validatePhoneAndTel;
exports.validateSameObj = validateSameObj;
require("core-js/modules/es.object.entries.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.object.values.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.trim.js");
/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(http?:|https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === "string" || str instanceof String) {
    return true;
  }
  return false;
}
/**
 * @param {string} num
 * @returns {Boolean}
 */
function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(arg) === "[object Array]";
  }
  return Array.isArray(arg);
}
/**
 * 校验合法手机号或者座机号
 * @param  {String}
 * @returns {Boolean}
 */
function validatePhoneAndTel(value) {
  if (!value) return false;
  var phone = /^1[3456789]\d{9}$/; //手机电话
  var tel = /^((0\d{2,3})-)?(\d{7,8})(-(\d{3,4}))?$/; //座机电话
  if (phone.test(value) || tel.test(value)) {
    return true;
  } else {
    return false;
  }
}
/**
 * 校验两个对象是否一样
 * @returns {Boolean}
 * @param obj
 * @param obj2
 */
function validateSameObj(obj, obj2) {
  return Object.entries(obj).toString() === Object.entries(obj2).toString();
}
/**
 * 校验对象的值是否全是null
 * @returns {Boolean}
 * @param obj
 */
function validateObjHaveValue(obj) {
  return Object.values(obj).length > 0;
}