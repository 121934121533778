"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _dept = require("@/api/system/dept");
var dept = {
  state: {
    deptTree: []
  },
  mutations: {
    SET_DEPT_TREE: function SET_DEPT_TREE(state, list) {
      state.deptTree = list;
    }
  },
  actions: {
    // 获取部门树
    GetDeptTree: function GetDeptTree(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        (0, _dept.treeselect)().then(function (res) {
          commit("SET_DEPT_TREE", res.data);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  }
};
var _default = exports.default = dept;