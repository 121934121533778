var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "navbar" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("hamburger", {
              staticClass: "hamburger-container",
              attrs: {
                id: "hamburger-container",
                "is-active": _vm.sidebar.opened,
              },
              on: { toggleClick: _vm.toggleSideBar },
            }),
            _c("breadcrumb", {
              staticClass: "breadcrumb-container",
              attrs: { id: "breadcrumb-container" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _vm.device !== "mobile"
              ? [
                  _c("search", {
                    staticClass: "right-menu-item",
                    attrs: { id: "header-search" },
                  }),
                  _c("screenfull", {
                    staticClass: "right-menu-item hover-effect",
                    attrs: { id: "screenfull" },
                  }),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "布局大小",
                        effect: "dark",
                        placement: "bottom",
                      },
                    },
                    [
                      _c("size-select", {
                        staticClass: "right-menu-item hover-effect",
                        attrs: { id: "size-select" },
                      }),
                    ],
                    1
                  ),
                  _vm.info.nurseryData && _vm.info.nurseryData.memberType
                    ? _c("div", { staticClass: "box" }, [
                        _vm._v(" " + _vm._s(_vm.getMemberType())),
                        _vm.info.nurseryData &&
                        _vm.info.nurseryData.surplusDay > 0
                          ? _c("span", [
                              _vm._v(
                                "，剩余" +
                                  _vm._s(_vm.info.nurseryData.surplusDay) +
                                  "天"
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.info.nurseryData &&
                  _vm.info.nurseryData.memberType &&
                  _vm.info.nurseryData.memberType !== 4
                    ? _c(
                        "span",
                        {
                          staticClass: "tip",
                          on: {
                            click: function ($event) {
                              return _vm.goPay()
                            },
                          },
                        },
                        [_vm._v("去续费")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "user-avatar-box" },
                    [
                      _c("el-image", {
                        staticClass: "user-avatar",
                        attrs: { fit: "cover", src: _vm.avatar },
                      }),
                      _vm.info.authStatus
                        ? _c(
                            "div",
                            {
                              staticClass: "box-tip",
                              class: { isActive: _vm.info.authStatus === 1 },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.info.authStatus === 1
                                      ? "认证"
                                      : "未认证"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/user/profile" } },
                      [_c("el-dropdown-item", [_vm._v("个人中心")])],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            _vm.setting = true
                          },
                        },
                      },
                      [_c("span", [_vm._v("布局设置")])]
                    ),
                    _c("el-dropdown-item", { attrs: { divided: "" } }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: "https://support.qq.com/products/532287",
                          },
                        },
                        [_vm._v("帮助与反馈")]
                      ),
                    ]),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout($event)
                          },
                        },
                      },
                      [_c("span", [_vm._v("退出登录")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
      _c("Pay", {
        ref: "payRef",
        model: {
          value: _vm.payShow,
          callback: function ($$v) {
            _vm.payShow = $$v
          },
          expression: "payShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }