var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payPage" }, [
    _c("div", { staticClass: "msg" }, [
      _c("div", { staticClass: "msg_head" }, [
        _vm._v(_vm._s(_vm.info.moduleName)),
      ]),
      _c("span", { staticClass: "msg_tip" }, [
        _vm._v(_vm._s(_vm.info.introduce)),
      ]),
      _c(
        "div",
        { staticClass: "msg_body" },
        _vm._l(_vm.introduceSubList, function (i, index) {
          return _c("span", { key: index, staticClass: "item" }, [
            _vm._v(_vm._s(i)),
          ])
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "line" }),
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm.status === 2 || _vm.status === 3
          ? [
              _c("res-page", {
                attrs: { status: _vm.status },
                on: { done: _vm.done, againPay: _vm.againPay },
              }),
            ]
          : [
              _c(
                "div",
                { staticClass: "body_box" },
                _vm._l(_vm.product, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "box",
                      class: { active: _vm.productId === item.id },
                      on: {
                        click: function ($event) {
                          return _vm.change(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon" }, [
                        _vm._v(_vm._s(item.introduce)),
                      ]),
                      _c("span", [_vm._v(_vm._s(item.unit))]),
                      _c("span", { staticClass: "value" }, [
                        _c("em", [_vm._v("￥")]),
                        _vm._v(_vm._s(item.priceYuan)),
                      ]),
                      item.originalPriceYuan
                        ? _c("span", { staticClass: "original_value" }, [
                            _c("em", [_vm._v("￥")]),
                            _vm._v(_vm._s(item.originalPriceYuan) + " "),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "body_pay" }, [
                _c("div", { staticClass: "pay_title" }, [
                  _c("span", [_vm._v("付款码")]),
                  _c(
                    "div",
                    { staticClass: "tips" },
                    [
                      _c("el-popover", {
                        attrs: {
                          placement: "bottom-start",
                          width: 248,
                          trigger: "hover",
                          "visible-arrow": false,
                          "popper-class": "have-prompt",
                          content:
                            "如果您支付遇到问题，可联系专属客服【15824425011】为您处理。",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "reference",
                            fn: function () {
                              return [
                                _c("img", {
                                  attrs: {
                                    src: _vm.oss_url + "question_mark_icon.png",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("span", [_vm._v("支付遇到问题？")]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("PayCode", {
                      ref: "PayCodeRef",
                      attrs: {
                        show: _vm.show,
                        productId: _vm.productId,
                        temId: _vm.temId,
                      },
                      on: { sendStatus: _vm.getStatus },
                    }),
                    _c("div", { staticClass: "content_info" }, [
                      _c("span", { staticClass: "money" }, [
                        _vm._v("实付：" + _vm._s(_vm.priceValue) + "元"),
                      ]),
                      _c("span", { staticClass: "way" }, [
                        _vm._v("使用微信/支付宝扫码支付"),
                      ]),
                      _c("span", { staticClass: "tip" }, [
                        _vm._v("支付即视为你同意"),
                        _c("em", { on: { click: _vm.goAgreement } }, [
                          _vm._v("相关协议"),
                        ]),
                        _vm._v("条例"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }