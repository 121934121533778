"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.trim.js");
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
var _DES = require("@/utils/DES");
var _customizationScreen = require("@/utils/customizationScreen");
var user = {
  state: {
    token: (0, _auth.getToken)(),
    name: "",
    avatar: "",
    roles: [],
    permissions: [],
    nurseryToken: "",
    nurserylogo: "",
    //添加配置类
    nurseryData: {
      nurserylogo: "",
      nurseryName: ""
    },
    nurseryName: "",
    info: {}
  },
  mutations: {
    SET_INFO: function SET_INFO(state, info) {
      state.info = info;
    },
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_nurseryToken: function SET_nurseryToken(state, nurseryToken) {
      state.nurseryToken = nurseryToken;
    },
    SET_Logo: function SET_Logo(state, logo) {
      state.nurserylogo = logo;
    },
    SET_nurseryName: function SET_nurseryName(state, name) {
      state.nurseryName = name;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var username = userInfo.username.trim();
      var password = (0, _DES.encryptByDES)(userInfo.password, "password");
      var captchaVerification = userInfo.captchaVerification;
      return new Promise(function (resolve, reject) {
        (0, _login.login)(username, password, captchaVerification).then(function (res) {
          (0, _auth.setToken)(res.token);
          commit("SET_TOKEN", res.token);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 海亮通过token进入
    HailiangLogin: function HailiangLogin(_ref2, token) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        if (token && token !== "undefined") {
          (0, _auth.setToken)(token);
          commit("SET_TOKEN", token);
          resolve();
        } else {
          reject();
        }
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)(state.token).then(function (res) {
          var user = res.user;
          commit("SET_INFO", user);
          //设置幼儿园基础信息
          if (user.nurseryData != null) {
            commit("SET_Logo", user.nurseryData.nurseryLogo);
            commit("SET_nurseryName", user.nurseryData.nurseryName);
          }
          //配置幼儿园头像
          // 头像为空先用获取幼儿园头像在获取公司logo
          var avatar = user.avatar === "" ? user.nurserylogo === "" ? require("@/assets/logo/logo.png") : user.nurserylogo : user.avatar;
          // const avatar =
          //   user.avatar === ""
          //     ? require("@/assets/logo/logo.png")
          //     // : apiUrl + user.avatar;
          //     : user.avatar;
          if (res.roles && res.roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit("SET_ROLES", res.roles);
            commit("SET_PERMISSIONS", res.permissions);
          } else {
            commit("SET_ROLES", ["ROLE_DEFAULT"]);
          }
          commit("SET_NAME", user.userName);
          commit("SET_AVATAR", avatar);
          commit("SET_nurseryToken", user.nurseryToken);
          (0, _customizationScreen.matchedNurseryToken)(user.nurseryToken);
          // if (user.nurseryData) {
          //   commit("SET_Logo", user.nurseryData.nurseryLogo);
          //   commit("SET_nurseryName", user.nurseryData.nurseryName);
          // }
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 退出系统
    LogOut: function LogOut(_ref4) {
      var commit = _ref4.commit,
        state = _ref4.state;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)(state.token).then(function () {
          commit("SET_TOKEN", "");
          commit("SET_ROLES", []);
          commit("SET_PERMISSIONS", []);
          (0, _auth.removeToken)();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve) {
        commit("SET_TOKEN", "");
        (0, _auth.removeToken)();
        resolve();
      });
    }
  }
};
var _default = exports.default = user;