var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c("transition", { attrs: { name: "fade-transform", mode: "out-in" } }, [
        _c(
          "div",
          { key: _vm.key },
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.dialog,
                  width: "50%",
                  "show-close": false,
                  "modal-append-to-body": "",
                },
              },
              [
                _c("div", { staticClass: "router-view-container" }, [
                  _c(
                    "video",
                    {
                      attrs: {
                        muted: "",
                        autoplay: "",
                        controls: "",
                        width: "500",
                        height: "300",
                        src: _vm.videoUrl,
                      },
                      domProps: { muted: true },
                    },
                    [_vm._v(" 抱歉，您的浏览器不支持嵌入视频！ ")]
                  ),
                  _c("strong", [
                    _vm._v("该模块权限暂未开启,欢迎联系我司管理员"),
                  ]),
                  _c("br"),
                  _c("strong", [_vm._v("联系电话：0571-58088906")]),
                ]),
              ]
            ),
            _c("router-view"),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }