var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "pay_code",
    },
    [
      _vm.payObj.status === 0
        ? _c(
            "div",
            { staticClass: "no_code", on: { click: _vm.createOrder } },
            [
              _c("el-image", {
                staticClass: "image_icon",
                attrs: { src: _vm.oss_url + "pay_code_icon.png" },
              }),
              _c("span", { staticClass: "btn" }, [_vm._v("点击生成")]),
              _c("span", [_vm._v("支付二维码")]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "code" },
            [
              _c("qrcode", {
                staticClass: "share_code_qrcode",
                attrs: {
                  id: "picture",
                  url: _vm.payObj.payQR,
                  iconurl: _vm.payObj.payQRLog,
                },
              }),
              _vm.payObj.status === 4
                ? _c(
                    "div",
                    { staticClass: "mock", on: { click: _vm.createOrder } },
                    [
                      _c("i", { staticClass: "el-icon-refresh" }),
                      _c("span", { staticClass: "mock_msg" }, [
                        _vm._v("二维码已失效"),
                      ]),
                      _c("span", { staticClass: "reCode" }, [
                        _vm._v("点击刷新"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }