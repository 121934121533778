var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resPage" },
    [
      _c("el-image", {
        staticClass: "image",
        attrs: {
          src:
            _vm.oss_url +
            (_vm.status === 3 ? "pay_fail.png" : "pay_success.png"),
        },
      }),
      _c("span", { staticClass: "msg" }, [
        _vm._v("支付" + _vm._s(_vm.status === 3 ? "失败" : "成功")),
      ]),
      _c("div", { staticClass: "done", on: { click: _vm.done } }, [
        _vm._v("完成"),
      ]),
      _vm.status === 3
        ? _c("span", { staticClass: "again", on: { click: _vm.againPay } }, [
            _vm._v("重新支付 >"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }