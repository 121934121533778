var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _c("sidebar", {
        class: ["sidebar-container", _vm.dialog && "dialogIndex"],
      }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            {
              staticClass: "main-container-header",
              class: { "fixed-header": _vm.fixedHeader },
            },
            [_c("navbar"), _vm.needTagsView ? _c("tags-view") : _vm._e()],
            1
          ),
          _c("app-main"),
          _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e(),
        ],
        1
      ),
      _vm.isChat
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "draggable",
                  rawName: "v-draggable:click",
                  value: _vm.draggableClick,
                  expression: "draggableClick",
                  arg: "click",
                },
              ],
              staticClass: "gpt_nav_area",
              staticStyle: { left: "140px", bottom: "100px" },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gpt_nav_btn" }, [
      _c("div", { staticClass: "btn_top" }, [_vm._v("立即体验AI助教")]),
      _c("span", { staticClass: "btn_bottom" }, [_vm._v("点我试试")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }