/*
 * @Description: 动态变量配置
 * @Author: xiaolinhuang
 * @Date: 2021-09-27 16:05:58
 * @LastEditors: xiaolinhuang
 * @LastEditTime: 2021-09-28 15:06:24
 */
module.exports = {
  /**
   * 请求地址
   */
  apiUrl: process.env.VUE_APP_BASE_API,
  // apiUrl: "http://192.168.0.101:3000",
  /**
   * 低代码地址
   */
  nocodeUrl: process.env.VUE_APP_NOCODE_API,
  /**
   * 访客人脸H5地址
   */
  UPLOADFACEH5Url: process.env.VUE_APP_UPLOADFACEH5_API
  /*测试环境*/
  // nocodeUrl: "http://192.168.1.136:3000",
};