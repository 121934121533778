"use strict";

require("core-js/modules/es.string.fontsize.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.fontsize = fontsize;
function fontsize(size) {
  var clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (!clientWidth) return;
  var fontSize = 100 * (clientWidth / 1920);
  return size / 100 * fontSize;
}
var _default = exports.default = {
  fontsize: fontsize
};