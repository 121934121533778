"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  // 被绑定的元素插入父节点的时候调用(父节点存在即可调用，不必存在document中)
  inserted: function inserted(el, binding, vnode) {
    el.style.cursor = "move";
    el.onmousedown = function (e) {
      var disx = e.pageX - el.offsetLeft;
      var disy = e.pageY - el.offsetTop;
      var keepLeft = parseInt(el.style.left);
      var keepTop = parseInt(el.style.top);
      document.onmousemove = function (e) {
        var x = e.pageX - disx;
        var y = e.pageY - disy;
        var maxX = document.body.clientWidth - parseInt(window.getComputedStyle(el).width);
        var maxY = document.body.clientHeight - parseInt(window.getComputedStyle(el).height);
        if (x < 0) {
          x = 0;
        } else if (x > maxX) {
          x = maxX;
        }
        if (y < 0) {
          y = 0;
        } else if (y > maxY) {
          y = maxY;
        }
        el.style.left = x + "px";
        el.style.top = y + "px";
      };
      document.onmouseup = function () {
        document.onmousemove = document.onmouseup = null;
        var nowLeft = parseInt(el.style.left);
        var nowTop = parseInt(el.style.top);
        //  移动在50以内认为是点击事件
        if (typeof binding.value === "function" && Math.abs(keepLeft - nowLeft) < 50 && Math.abs(keepTop - nowTop) < 50) {
          binding.value.call(vnode.context);
        }
      };
    };
  }
};