"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
var _compositionApi = require("@vue/composition-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = (0, _compositionApi.defineComponent)({
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var againPay = function againPay() {
      emit("againPay");
    };
    var done = function done() {
      emit("done");
    };
    return {
      againPay: againPay,
      done: done
    };
  }
});