// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#383838",
	"menuActiveText": "#ffffff",
	"subMenuActiveText": "#595959",
	"menuBg": "#ffffff",
	"menuHover": "rgba(67, 119, 253, 0.4)",
	"subMenuBg": "#ffffff",
	"subMenuHover": "rgba(67, 119, 253, 0.2)",
	"sideBarWidth": "13.541667vw"
};
module.exports = exports;
