"use strict";

var _interopRequireDefault = require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-45320557-809359/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._debounce = _debounce;
exports.addClass = addClass;
exports.beautifierConf = void 0;
exports.byteLength = byteLength;
exports.camelCase = camelCase;
exports.cleanArray = cleanArray;
exports.createUniqueString = createUniqueString;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.flattenTree = exports.exportDefault = void 0;
exports.flatteningTree = flatteningTree;
exports.formatDate = formatDate;
exports.formatTime = formatTime;
exports.formatTree = void 0;
exports.getQueryObject = getQueryObject;
exports.getRandomStr = void 0;
exports.getTime = getTime;
exports.hasClass = hasClass;
exports.html2Text = html2Text;
exports.isEmptyObject = isEmptyObject;
exports.isKeyUnique = isKeyUnique;
exports.isNumberStr = isNumberStr;
exports.makeMap = makeMap;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.removeClass = removeClass;
exports.titleCase = titleCase;
exports.toggleClass = toggleClass;
exports.uniqueArr = uniqueArr;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-45320557-809359/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
var _toConsumableArray2 = _interopRequireDefault(require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-45320557-809359/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _typeof2 = _interopRequireDefault(require("/root/codingci/tools/jenkins_home/workspace/3828837-cci-45320557-809359/node_modules/@babel/runtime/helpers/typeof.js"));
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.set.js");
require("core-js/modules/es.set.difference.v2.js");
require("core-js/modules/es.set.intersection.v2.js");
require("core-js/modules/es.set.is-disjoint-from.v2.js");
require("core-js/modules/es.set.is-subset-of.v2.js");
require("core-js/modules/es.set.is-superset-of.v2.js");
require("core-js/modules/es.set.symmetric-difference.v2.js");
require("core-js/modules/es.set.union.v2.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
var getRandomStr = exports.getRandomStr = function getRandomStr() {
  return new Date().getTime() + Math.random().toString(16).slice(2);
};

/**
 * 表格时间格式化
 */
function formatDate(cellValue) {
  if (cellValue == null || cellValue == "") return "";
  var date = new Date(cellValue);
  var year = date.getFullYear();
  var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf("?") + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return "";
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return "";
    return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
  })).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var _later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(_later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(_later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
// 新防抖，与上面的区别是，可以防止参数丢失，上面会参数丢失
function _debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var _later2 = function later() {
    var last = +new Date() - timestamp;
    if (last < wait && last > 0) {
      timeout = setTimeout(_later2, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    context = this;
    args = arguments;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(_later2, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + "";
  var randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}
function makeMap(str, expectsLowerCase) {
  var map = Object.create(null);
  var list = str.split(",");
  for (var i = 0; i < list.length; i++) {
    map[list[i]] = true;
  }
  return expectsLowerCase ? function (val) {
    return map[val.toLowerCase()];
  } : function (val) {
    return map[val];
  };
}
var exportDefault = exports.exportDefault = "export default ";
var beautifierConf = exports.beautifierConf = {
  html: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "separate",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "normal",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
};

// 首字母大小
function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, function (L) {
    return L.toUpperCase();
  });
}

// 下划转驼峰
function camelCase(str) {
  return str.replace(/-[a-z]/g, function (str1) {
    return str1.substr(-1).toUpperCase();
  });
}
function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}
// 扁平树结构
var _flattenTree = exports.flattenTree = function flattenTree() {
  var tree = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return tree.reduce(function (result, node) {
    result.push(node);
    if (node.children && node.children.length > 0) {
      result.push.apply(result, (0, _toConsumableArray2.default)(_flattenTree(node.children)));
    }
    return result;
  }, []);
};
/**
   * @name: 权限数扁平化
   * @param {Array} arr 权限数据
   * @return: {Array}
   * @description: 把权限数据扁平化，变成一维数组，返回[{id:1,label:'分1'},{id:2,label:'分1下大2班'}....]
   * @author: lvhao
   * @example:
   *  flatteningTree([
    {
      "id": 473,
      "label": "分园一",
      "children": [
        {
          "id": 474,
          "label": "大班级1",
          "nurseryToken": "53f5a616dfe24a3ba2937822f3fa2f73",
        },
        {
          "id": 475,
          "label": "小二班",
          "nurseryToken": "53f5a616dfe24a3ba2937822f3fa2f73"
        },
        {
          "id": 532,
          "label": "我是超级长的班级名称",
          "nurseryToken": "53f5a616dfe24a3ba2937822f3fa2f73"
        }
      ],
      "nurseryToken": "53f5a616dfe24a3ba2937822f3fa2f73"
    }
    [
      {"id": 473,"label": "分园一"},
      {"id": 474,"label": "大班级1"},
      {"id": 475,"label": "小二班"},
      {"id": 532,"label": "我是超级长的班级名称"}
    ]
  ])
   * */
function flatteningTree(arr) {
  var data = [];
  while (arr.length > 0) {
    var item = arr[0];
    arr.shift();
    data.push({
      id: item.id,
      label: item.label
    });
    if (Array.isArray(item.children) && item.children.length > 0) {
      arr = item.children.concat(arr);
    }
  }
  return data;
}
/**
   * @name: 格式化数组的值
   * @param {Array} arr 权限数据
   * @param {Object} target 处理后的目标格式
   * @param {Object} obj 要处理的参数
   * @return: {Array}
   * @description: 将处置的k-v处理为我们想要的值
   * @example:
   * formatTree([
    {
      "deptId": 473,
      "deptName": "分园一",
      "children": [
        {
          "deptId": 474,
          "deptName": "大班级1",     
        },
      ],
    }
  ])
}
  [
    {
      "id": 473,
      "label": "分园一",
      "children": [
        {
          "id": 474,
          "label": "大班级1",
        },
      ],
    }
  ]
   * */
var _formatTree = exports.formatTree = function formatTree(arr) {
  var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    key: "id",
    label: "label"
  };
  var obj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    key: "deptId",
    label: "deptName"
  };
  arr.forEach(function (i) {
    i[target.label] = i[obj.label];
    i[target.key] = i[obj.key];
    if (i.children && i.children.length > 0) {
      _formatTree(i.children, target, obj);
    } else {
      delete i.children;
    }
  });
  return arr;
};
/**
 * 判断一个对象是否为空对象
 * @param {Object} obj - 待判断的对象
 * @returns {boolean} - 如果对象为空对象则返回true，否则返回false
 */
function isEmptyObject(obj) {
  return JSON.stringify(obj) === "{}";
}
/**
 * 检查数组中的键值是否唯一
 * @param {Array} arr - 待检查的数组
 * @param {String} key - 数组对象中的键名，用于检查唯一性
 * @return {Boolean} - 如果所有键值都唯一，则返回true；否则返回false。
 */
function isKeyUnique(arr, key) {
  var seenValues = new Set();
  var _iterator = (0, _createForOfIteratorHelper2.default)(arr),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      if (seenValues.has(item[key])) {
        // 如果已经见过这个属性值，说明不唯一
        return false;
      }
      seenValues.add(item[key]); // 添加当前属性值到集合中
    }
    // 所有属性值都独一无二
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return true;
}