var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "pay_dialog",
      attrs: { visible: _vm.show, width: "966px", "append-to-body": true },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        { staticClass: "header", attrs: { slot: "title" }, slot: "title" },
        [
          _c(
            "div",
            { staticClass: "header_bar" },
            [
              _c("el-image", {
                staticClass: "avatar",
                attrs: { src: _vm.info.avatar },
              }),
              _c("div", { staticClass: "info" }, [
                _c("span", [_vm._v(_vm._s(_vm.info.nickName))]),
                _c("span", { staticClass: "info_type" }, [
                  _vm._v(_vm._s(_vm.getMemberType())),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "header_nav" },
            _vm._l(_vm.nav, function (i) {
              return _c(
                "div",
                {
                  key: i.id,
                  staticClass: "nav",
                  class: { active: _vm.activeItem.id === i.id },
                  on: {
                    click: function ($event) {
                      return _vm.change(i)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(i.title) + " ")]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c("PayPage", {
            ref: "pagesRef",
            attrs: { show: _vm.show, info: _vm.activeItem },
            on: { done: _vm.close },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }