"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  CustomizedToken: function CustomizedToken(state) {
    return state.customized.CustomizedToken;
  },
  //七彩幼儿园token
  CustomizedTokenForJiaaogongxue: function CustomizedTokenForJiaaogongxue(state) {
    return state.customized.CustomizedTokenForJiaaogongxue;
  },
  //乐清市佳澳公学幼儿园
  CustomizedTokenForMengdefeier: function CustomizedTokenForMengdefeier(state) {
    return state.customized.CustomizedTokenForMengdefeier;
  },
  //蒙德斐尔幼儿园
  CustomizedTokenForXinguang: function CustomizedTokenForXinguang(state) {
    return state.customized.CustomizedTokenForXinguang;
  },
  //无锡市新光幼儿园数据大屏
  CustomizedTokenForLiShui: function CustomizedTokenForLiShui(state) {
    return state.customized.CustomizedTokenForLiShui;
  },
  //新和县丽水幼儿园
  CustomizedTokenForHaiLiang: function CustomizedTokenForHaiLiang(state) {
    return state.customized.CustomizedTokenForHaiLiang;
  },
  //海亮
  CustomizedTokenForGuoXin: function CustomizedTokenForGuoXin(state) {
    return state.customized.CustomizedTokenForGuoXin;
  } //国信嘉园
};
var _default = exports.default = getters;