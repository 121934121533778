"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var customized = {
  state: {
    CustomizedToken: false,
    //七彩token
    CustomizedTokenForJiaaogongxue: false,
    //乐清市佳澳公学幼儿园
    CustomizedTokenForMengdefeier: false,
    // 蒙德斐尔
    CustomizedTokenForXinguang: false,
    //无锡市新光幼儿园数据大屏
    CustomizedTokenForLiShui: false,
    //新和县丽水幼儿园
    CustomizedTokenForHaiLiang: false,
    //海亮
    CustomizedTokenForGuoXin: false,
    //国信嘉园
    // 2024/07/28 托育需求迁移刀wen端冲突 代码找回
    CustomizedTokenForJiaoZuo: false //新疆焦作幼儿园
  },
  mutations: {
    SET_CustomizedToken: function SET_CustomizedToken(state, res) {
      state.CustomizedToken = res;
    },
    SET_CustomizedTokenForJiaaogongxue: function SET_CustomizedTokenForJiaaogongxue(state, res) {
      state.CustomizedTokenForJiaaogongxue = res;
    },
    SET_CustomizedTokenForMengdefeier: function SET_CustomizedTokenForMengdefeier(state, res) {
      state.CustomizedTokenForMengdefeier = res;
    },
    SET_CustomizedTokenForXinguang: function SET_CustomizedTokenForXinguang(state, res) {
      state.CustomizedTokenForXinguang = res;
    },
    SET_CustomizedTokenForLiShui: function SET_CustomizedTokenForLiShui(state, res) {
      state.CustomizedTokenForLiShui = res;
    },
    SET_CustomizedTokenForHaiLiang: function SET_CustomizedTokenForHaiLiang(state, res) {
      state.CustomizedTokenForHaiLiang = res;
    },
    SET_CustomizedTokenForGuoXin: function SET_CustomizedTokenForGuoXin(state, res) {
      state.CustomizedTokenForGuoXin = res;
    },
    SET_CustomizedTokenForJiaoZuo: function SET_CustomizedTokenForJiaoZuo(state, res) {
      state.CustomizedTokenForJiaoZuo = res;
    }
  }
};
var _default = exports.default = customized;